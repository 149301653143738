import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore } from '../utils/store.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tabActive: getStore({ name: 'tabActive' }) || '1',
    pressDetail: getStore({ name: 'pressDetail' }) || {},
    transitionMode: 'out-in',
    tabHref: getStore({ name: 'tabHref' }) || {},
    pressData: {}
  },
  getters: {
    tabActive: state => {
      return state.tabActive
    },
    pressDetail: state => {
      return state.pressDetail
    },
    pressData: state => {
      return state.pressData
    },
    tabHref: state => {
      return state.tabHref
    }
  },
  mutations: {
    setData (state, { name, value, store = false }) {
      if (store) {
        setStore({ name: name, content: value, type: 'session' })
      }
      state[name] = value
    }
  },
  actions: {
  },
  modules: {
  }
})
