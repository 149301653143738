<template>
  <div :class="prefixCls">
    <div class="box">
      <Logo></Logo>
      <Tabs></Tabs>
    </div>
  </div>
</template>

<script>
import Logo from './logo.vue'
import Tabs from './tabs.vue'
export default {
  data () {
    return {
      prefixCls: this.$prefix + '-layout-header'
    }
  },
  created () {
  },
  components: {
    Logo,
    Tabs
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -layout-header;
  .#{$prefixCls}{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    z-index: 100;
    background-color: #fff;
    top: 0;
    .box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: $content-width;
      height: 60px;
      padding: 10px 0;
      margin: 0 auto;
    }
  }
</style>
