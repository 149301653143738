import Vue from 'vue'
import VueRouter from 'vue-router'
import { solutions } from './modules/solutions.js'
import { aochenRouter } from './modules/aochen.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Layout" */ '../pages/layout/index.vue')
  },
  ...solutions,
  ...aochenRouter,
  {
    path: '/home',
    name: 'Home',
    meta: {
      keepAlive: true,
      active: '1'
    },
    component: () => import('../pages/home/index.vue')
  },
  {
    path: '/press',
    name: 'Press',
    meta: {
      keepAlive: true,
      active: '3'
    },
    redirect: '/press/overview',
    children: [
      {
        path: '/press/overview',
        name: 'OverView',
        component: () => import('../pages/press/components/RDOverview.vue')
      },
      {
        path: '/press/strategy',
        name: 'Strategy',
        component: () => import('../pages/press/components/RDStrategy.vue')
      },
      {
        path: '/press/talents',
        name: 'Talents',
        component: () => import('../pages/press/components/InnovativeTalents.vue')
      },
      {
        path: '/press/new',
        name: 'New',
        component: () => import('../pages/press/components/news.vue')
      }
    ],
    component: () => import('../pages/press/index.vue')
  },
  {
    path: '/press/detail',
    name: 'PressDetail',
    meta: {
      keepAlive: false,
      active: '3'
    },
    props: route => ({ id: route.query.id }),
    component: () => import('../views/detail/press.vue')
  },
  {
    path: '/press/list',
    name: 'PressList',
    meta: {
      keepAlive: false,
      active: '3'
    },
    props: route => ({ type: route.query.type }),
    component: () => import('../views/list/press.vue')
  },
  {
    path: '/cases',
    name: 'Cases',
    meta: {
      keepAlive: true,
      active: '4'
    },
    redirect: '/cases/dynamics',
    children: [
      {
        path: '/cases/dynamics',
        name: 'aochenDynamics',
        component: () => import('../pages/cases/components/dynamics.vue')
      },
      {
        path: '/cases/industry',
        name: 'aochenIndustry',
        component: () => import('../pages/cases/components/industry.vue')
      },
      {
        path: '/cases/info',
        name: 'aochenInfo',
        component: () => import('../pages/cases/components/info.vue')
      }
    ],
    component: () => import('../pages/cases/index.vue')
  },
  {
    path: '/manpower',
    name: 'Manpower',
    component: () => import('../pages/manpower'),
    redirect: '/manpower/person',
    meta: {
      active: '2',
      keepAlive: true
    },
    children: [
      {
        path: '/manpower/person',
        name: 'Person',
        component: () => import('../pages/manpower/components/person.vue')
      },
      {
        path: '/manpower/invite',
        name: 'Invite',
        component: () => import('../pages/manpower/components/invite.vue')
      },
      {
        path: '/manpower/edu',
        name: 'Edu',
        component: () => import('../pages/manpower/components/edu.vue')
      }
    ]
  },
  {
    path: '/core/:searchType?/:id?',
    name: 'Core',
    meta: {
      keepAlive: true,
      active: '19'
    },
    component: () => import('../pages/core/index.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../pages/contact'),
    meta: {
      active: 7
    }
  },
  {
    path: '/partner',
    name: 'Partner',
    meta: {
      active: '6'
    },
    component: () => import('../pages/partner/index.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      keepAlive: false
    },
    component: () => import('../pages/detail/index.vue')
  },
  {
    path: '/notice-detail',
    name: 'NoticeDetail',
    meta: {
      keepAlive: false
    },
    component: () => import('../pages/noticeDetail/index.vue')
  },
  {
    path: '/videoDetail',
    name: 'VideoDetail',
    meta: {
      keepAlive: false
    },
    component: () => import('../pages/video-detail/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// function isMobile () {
//   const userAgentInfo = navigator.userAgent
//   const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
//   const getArr = Agents.filter(i => userAgentInfo.includes(i))
//   return !!getArr.length
// }

// router.beforeEach((to, from, next) => {
//   if (isMobile()) {
//     const url = `http://aaa.csufu.com/websiteMobile/#${to.fullPath}`
//     window.open(url, '_self')
//   }
//   // store.commit('setData', {
//   //   name: 'tabActive',
//   //   value: to.meta.active,
//   //   store: true
//   // })
//   next()
// })
export default router
