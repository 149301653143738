<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-guide`">
      <div :class="`${prefixCls}-guide-tabs`">
        <div v-for="item in tabsList" :key="item.id" :class="`${prefixCls}-guide-tabs-item`">
          <div :class="`${prefixCls}-guide-tabs-item-content`">
            <div :class="[`${prefixCls}-guide-tabs-item-content-label`, item.children.length === 0 ? 'click' : null]" @click="handleGo(item)">
              {{item.label}}
            </div>
            <div :class="`${prefixCls}-guide-tabs-item-content-children`" v-for="value in item.children" :key="value.id" @click="goTab(value)">
              {{value.label}}
            </div>
          </div>
        </div>
      </div>
      <div :class="`${prefixCls}-guide-intor`">
        <div :class="`${prefixCls}-guide-intor-title`">
          <div :class="`${prefixCls}-guide-intor-title-cn`">
            舟山金鳌海洋集团有限公司
          </div>
        </div>
        <div :class="`${prefixCls}-guide-intor-further`">
          <div>
            地址：舟山市普陀区东港街道海洲路721号永跃大厦12楼
          </div>
          <div>
            电话：17705806786
          </div>
          <div>
            传真：/
          </div>
          <div>
            邮箱：jajtbgs@jinaog.com
          </div>
<!--          <div>-->
<!--            网址：www.xxx.com-->
<!--          </div>-->
        </div>
        <div :class="`${prefixCls}-guide-intor-qrcode`">

        </div>
      </div>
    </div>
    <div :class="`${prefixCls}-company`">

    </div>
    <div :class="`${prefixCls}-copyright`">
      ©2023舟山金鳌海洋集团有限公司 版权所有 <a style="color:white;" target="view_window" href="https://beian.miit.gov.cn/">浙ICP备2023025280号</a>
      友情链接 <a style="color:white;" target="view_window" href="https://www.jahyjt.com">舟山普陀金鳌船艇管理服务有限公司</a>
    </div>
<!--      <div :class="`${prefixCls}-copyright`" style="margin-top: 0">-->
<!--          互联网药品信息服务资格证书：（浙）-非经营性-2016-0035-->
<!--      </div>-->
  </div>
</template>

<script>
import { tabsList } from './header/data'
export default {
  data () {
    return {
      prefixCls: this.$prefix + '-layout-footer',
      tabsList: tabsList.slice(1, 6)
    }
  },
  created () {
  },
  methods: {
    handleGo (tab) {
      if (tab.length !== 0) return
      if (tab.path !== this.$route.path) {
        // 目标路由对象
        let target = null
        this.$router.getRoutes().findIndex(route => {
          if (route.path === tab.path) {
            target = route
            return true
          }
          return false
        })
        // 解析路由对象获取href
        const { href } = this.$router.resolve(target || {})
        window.open(href, '_blank')
      } else {
        document.querySelector(tab.href).scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
    goTab (tab) {
      if (tab.path !== this.$route.path) {
        // 解析路由对象获取href
        const { href } = this.$router.resolve(tab.path || {})
        window.open(href, '_blank')
      } else {
        document.querySelector(tab.href).scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -layout-footer;
  .#{$prefixCls}{
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #F4F6F7;
    background-color: var(--theme-color);
    &-guide{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: $content-width;
      margin-top: 50px;
      &-tabs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 60%;
        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-content {
            display: flex;
            flex-direction: column;
            &-label {
              color: #fff;
              font-size: 18px;
              font-weight: bold;
              margin: 20px;
              &.click {
                cursor: pointer;
                &:hover{
                  color: $primary-color;
                }
              }
            }
            &-children {
              display: flex;
              flex-direction: column;
              padding: 10px;
              font-size: 16px;
              color: rgba(255,255,255,.8);
              font-weight: bold;
              cursor: pointer;
              &:hover{
                color: $primary-color;
              }
            }
          }
        }
      }
      &-intor{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin-left: 50px;
        &-title{
          display: flex;
          flex-direction: column;
          color: #fff;
          &-cn{
            font-size: 26px;
            line-height: 1.5em;
            margin: 16px 0 16px;
          }
          &-en {
            font-size: 14px;
            line-height: 1.5em;
            margin-bottom: 12px;
            text-align: left;
          }
        }
        &-further{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 14px;
          line-height: 2em;
          color: rgba(255,255,255,.7);
          font-weight: bold;
          text-align: left;
        }
        &-qrcode{
          img {
            width: 95px;
            height: 95px;
          }
        }
      }
    }
    &-copyright{
      text-align: center;
      font-size: 14px;
      margin-top: 56px;
      color: rgba(255,255,255,.7);
      font-weight: bold;
      padding-bottom: 20px;
    }
  }
</style>
