export const solutions = [
  {
    path: '/solutions',
    name: 'solutionsPage',
    redirect: '/solutions/work',
    meta: {
      active: '5'
    },
    component: () => import('../../views/solutions/index.vue'),
    children: [
      {
        path: '/solutions/work',
        name: 'solutionsWork',
        meta: {
          active: '5'
        },
        component: () => import('../../views/solutions/work')
      },
      {
        path: '/solutions/build',
        name: 'solutionsCity',
        meta: {
          active: '5'
        },
        component: () => import('../../views/solutions/build')
      },
      {
        path: '/solutions/safe',
        name: 'solutionsSafe',
        meta: {
          active: '5'
        },
        component: () => import('../../views/solutions/safe')
      }
    ]
  }
]
