
export const aochenRouter = [
  {
    path: '/aochen',
    name: 'Aochen',
    redirect: '/aochen/intro',
    meta: {
      active: '2'
    },
    children: [
      {
        path: '/aochen/intro',
        name: 'AochenIntro',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/home/components/IntroductionVertical.vue')
      },
      // {
      //   path: '/aochen/chairman',
      //   name: 'AochenChairman',
      //   meta: {
      //     active: '2'
      //   },
      //   component: () => import('@/pages/aochen/components/chairman.vue')
      // },
      {
        path: '/aochen/culture',
        name: 'AochenChairman',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/aochen/components/culture.vue')
      },
      {
        path: '/aochen/care',
        name: 'AochenCare',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/aochen/components/care.vue')
      },
      {
        path: '/aochen/team',
        name: 'AochenTeam',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/aochen/components/team.vue')
      },
      {
        path: '/aochen/honors',
        name: 'QualificationsHonors',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/aochen/components/honors.vue')
      },
      {
        path: '/aochen/social',
        name: 'SocialResponsibility',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/aochen/components/social.vue')
      },
      {
        path: '/aochen/video',
        name: 'VideoCenter',
        meta: {
          active: '2'
        },
        component: () => import('@/pages/aochen/components/video.vue')
      }
    ],
    component: () => import('@/pages/aochen/index.vue')
  }
]
