<template>
  <div id="app">
    <Header></Header>
    <Layout class="layout"></Layout>
  </div>
</template>

<script>
import Layout from './pages/layout/index.vue'
import Header from './pages/layout/header/index.vue'

export default {
  name: 'App',
  components: {
    Layout,
    Header
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  overflow: hidden;
}
</style>
