export default {
  title: '海力生门户网站', // 网站标题(项目名称)
  storageKey: 'aochen-official-website', // 可设置为项目英文名 避免不同项目在同一环境下缓存互相串联
  indexPage: '/wel/index', // 项目首页地址
  prefix: 'aochen',
  indexTitle: '舟山普陀金鳌海洋管理服务门户网站', // 项目首页菜单上方显示的名称
  logo: '/websites/logo-white.png', // 项目或企业LOGO
  defaultAvatar: '/websites/images/default-avatar.jpg', // 默认用户头像
  iv: 'aochendemo@12345', // 加密偏移值
  fistPage: {
    label: '首页',
    value: '/wel/index',
    params: {},
    query: {},
    close: false,
    meta: {
      $keepAlive: true,
      isTab: true
    }
  },
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'menuName',
      path: 'path',
      icon: 'icon',
      children: 'children',
      meta: 'meta'
    }
  }
}

export const iconfontUrl = '//at.alicdn.com/t/font_$key.css'
export const iconFonts = ['2636982_0j8v5a51swrs'] // 字体图标库
