import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import setting from './config/website'
import ElementUI from 'element-ui'
import './design/index.scss'
import 'animate.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/swiper-bundle.css'
import 'wow.js/css/libs/animate.css'
import './design/iconfont.css'
import './design/elementUI.scss'
import './design/font.scss'

Vue.config.productionTip = false
Vue.prototype.$prefix = setting.prefix
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
